/* line 13, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricechart/module.scss */
.m-marketdata-sales-chart {
	background-color: rgb(var(--color-global-widget));
	margin-bottom: 20px;
}

/* line 17, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricechart/module.scss */
.m-marketdata-sales-chart .md-chart-graph {
	background-color: rgb(var(--color-global-widget));
}

/* line 23, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricechart/module.scss */
.marketdata-sales-chart__chart-legend-date {
	padding: 10px 20px;
	display: block;
}

/* line 28, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricechart/module.scss */
.marketdata-sales-chart__chart {
	background-color: rgb(var(--color-global-widget));
}

/* line 32, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricechart/module.scss */
.marketdata-sales-chart__legend {
	user-select: none;
	box-sizing: border-box;
	color: rgb(var(--color-global-positive));
	border: 2px solid transparent;
	padding: 5px;
	text-align: center;
	display: block;
	cursor: pointer;
}

/* line 43, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricechart/module.scss */
.marketdata-sales-chart__legend-toggle {
	display: none;
}

/* line 47, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricechart/module.scss */
.marketdata-sales-chart__legend-toggle:not(:checked) + .marketdata-sales-chart__legend {
	/* Important to override the JS set background color */
	background-color: transparent !important;
	color: rgb(var(--color-global-negative));
}

/* line 56, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricechart/module.scss */
.marketdata-sales-chart__chart-title {
	margin-bottom: 10px;
	text-transform: capitalize;
}

/* line 60, scss/80-themes/Saunders/70-modules/listingmarketdatamediansalespricechart/module.scss */
.marketdata-sales-chart__chart-title--colored {
	color: rgb(var(--color-global-text));
}

/*# sourceMappingURL=../../../../../true */